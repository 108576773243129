@media screen and (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (max-width: 767px) {
}.btn-primary-discreet {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-primary-discreet:hover,
.btn-primary-discreet:focus,
.btn-primary-discreet.focus,
.btn-primary-discreet:active,
.btn-primary-discreet.active,
.open > .dropdown-toggle.btn-primary-discreet {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.btn-primary-discreet:active,
.btn-primary-discreet.active,
.open > .dropdown-toggle.btn-primary-discreet {
  background-image: none;
}
.btn-primary-discreet.disabled,
.btn-primary-discreet[disabled],
fieldset[disabled] .btn-primary-discreet,
.btn-primary-discreet.disabled:hover,
.btn-primary-discreet[disabled]:hover,
fieldset[disabled] .btn-primary-discreet:hover,
.btn-primary-discreet.disabled:focus,
.btn-primary-discreet[disabled]:focus,
fieldset[disabled] .btn-primary-discreet:focus,
.btn-primary-discreet.disabled.focus,
.btn-primary-discreet[disabled].focus,
fieldset[disabled] .btn-primary-discreet.focus,
.btn-primary-discreet.disabled:active,
.btn-primary-discreet[disabled]:active,
fieldset[disabled] .btn-primary-discreet:active,
.btn-primary-discreet.disabled.active,
.btn-primary-discreet[disabled].active,
fieldset[disabled] .btn-primary-discreet.active {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary-discreet[disabled] {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-primary-discreet .badge {
  color: #fff;
  background-color: #333;
}
.btn-success-discreet {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-success-discreet:hover,
.btn-success-discreet:focus,
.btn-success-discreet.focus,
.btn-success-discreet:active,
.btn-success-discreet.active,
.open > .dropdown-toggle.btn-success-discreet {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.btn-success-discreet:active,
.btn-success-discreet.active,
.open > .dropdown-toggle.btn-success-discreet {
  background-image: none;
}
.btn-success-discreet.disabled,
.btn-success-discreet[disabled],
fieldset[disabled] .btn-success-discreet,
.btn-success-discreet.disabled:hover,
.btn-success-discreet[disabled]:hover,
fieldset[disabled] .btn-success-discreet:hover,
.btn-success-discreet.disabled:focus,
.btn-success-discreet[disabled]:focus,
fieldset[disabled] .btn-success-discreet:focus,
.btn-success-discreet.disabled.focus,
.btn-success-discreet[disabled].focus,
fieldset[disabled] .btn-success-discreet.focus,
.btn-success-discreet.disabled:active,
.btn-success-discreet[disabled]:active,
fieldset[disabled] .btn-success-discreet:active,
.btn-success-discreet.disabled.active,
.btn-success-discreet[disabled].active,
fieldset[disabled] .btn-success-discreet.active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success-discreet[disabled] {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-success-discreet .badge {
  color: #fff;
  background-color: #333;
}
.btn-info-discreet {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-info-discreet:hover,
.btn-info-discreet:focus,
.btn-info-discreet.focus,
.btn-info-discreet:active,
.btn-info-discreet.active,
.open > .dropdown-toggle.btn-info-discreet {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info-discreet:active,
.btn-info-discreet.active,
.open > .dropdown-toggle.btn-info-discreet {
  background-image: none;
}
.btn-info-discreet.disabled,
.btn-info-discreet[disabled],
fieldset[disabled] .btn-info-discreet,
.btn-info-discreet.disabled:hover,
.btn-info-discreet[disabled]:hover,
fieldset[disabled] .btn-info-discreet:hover,
.btn-info-discreet.disabled:focus,
.btn-info-discreet[disabled]:focus,
fieldset[disabled] .btn-info-discreet:focus,
.btn-info-discreet.disabled.focus,
.btn-info-discreet[disabled].focus,
fieldset[disabled] .btn-info-discreet.focus,
.btn-info-discreet.disabled:active,
.btn-info-discreet[disabled]:active,
fieldset[disabled] .btn-info-discreet:active,
.btn-info-discreet.disabled.active,
.btn-info-discreet[disabled].active,
fieldset[disabled] .btn-info-discreet.active {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info-discreet[disabled] {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-info-discreet .badge {
  color: #fff;
  background-color: #333;
}
.btn-warning-discreet {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-warning-discreet:hover,
.btn-warning-discreet:focus,
.btn-warning-discreet.focus,
.btn-warning-discreet:active,
.btn-warning-discreet.active,
.open > .dropdown-toggle.btn-warning-discreet {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning-discreet:active,
.btn-warning-discreet.active,
.open > .dropdown-toggle.btn-warning-discreet {
  background-image: none;
}
.btn-warning-discreet.disabled,
.btn-warning-discreet[disabled],
fieldset[disabled] .btn-warning-discreet,
.btn-warning-discreet.disabled:hover,
.btn-warning-discreet[disabled]:hover,
fieldset[disabled] .btn-warning-discreet:hover,
.btn-warning-discreet.disabled:focus,
.btn-warning-discreet[disabled]:focus,
fieldset[disabled] .btn-warning-discreet:focus,
.btn-warning-discreet.disabled.focus,
.btn-warning-discreet[disabled].focus,
fieldset[disabled] .btn-warning-discreet.focus,
.btn-warning-discreet.disabled:active,
.btn-warning-discreet[disabled]:active,
fieldset[disabled] .btn-warning-discreet:active,
.btn-warning-discreet.disabled.active,
.btn-warning-discreet[disabled].active,
fieldset[disabled] .btn-warning-discreet.active {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning-discreet[disabled] {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-warning-discreet .badge {
  color: #fff;
  background-color: #333;
}
.btn-danger-discreet {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-danger-discreet:hover,
.btn-danger-discreet:focus,
.btn-danger-discreet.focus,
.btn-danger-discreet:active,
.btn-danger-discreet.active,
.open > .dropdown-toggle.btn-danger-discreet {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.btn-danger-discreet:active,
.btn-danger-discreet.active,
.open > .dropdown-toggle.btn-danger-discreet {
  background-image: none;
}
.btn-danger-discreet.disabled,
.btn-danger-discreet[disabled],
fieldset[disabled] .btn-danger-discreet,
.btn-danger-discreet.disabled:hover,
.btn-danger-discreet[disabled]:hover,
fieldset[disabled] .btn-danger-discreet:hover,
.btn-danger-discreet.disabled:focus,
.btn-danger-discreet[disabled]:focus,
fieldset[disabled] .btn-danger-discreet:focus,
.btn-danger-discreet.disabled.focus,
.btn-danger-discreet[disabled].focus,
fieldset[disabled] .btn-danger-discreet.focus,
.btn-danger-discreet.disabled:active,
.btn-danger-discreet[disabled]:active,
fieldset[disabled] .btn-danger-discreet:active,
.btn-danger-discreet.disabled.active,
.btn-danger-discreet[disabled].active,
fieldset[disabled] .btn-danger-discreet.active {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger-discreet[disabled] {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-danger-discreet .badge {
  color: #fff;
  background-color: #333;
}
.modal-backdrop.am-fade {
  opacity: 0.5;
  transition: opacity 0.15s linear;
}
.modal-backdrop.am-fade.ng-enter {
  opacity: 0;
}
.modal-backdrop.am-fade.ng-enter.ng-enter-active {
  opacity: 0.5;
}
.modal-backdrop.am-fade.ng-leave {
  opacity: 0.5;
}
.modal-backdrop.am-fade.ng-leave.ng-leave-active {
  opacity: 0;
}
.datepicker.dropdown-menu {
  width: 250px;
  height: 270px;
}
.datepicker.dropdown-menu button {
  outline: none;
  border: 0px;
}
.datepicker.dropdown-menu tbody {
  height: 180px;
}
.datepicker.dropdown-menu tbody button {
  padding: 6px;
}
.datepicker.dropdown-menu.datepicker-mode-1 tbody button,
.datepicker.dropdown-menu.datepicker-mode-2 tbody button {
  height: 65px;
}
.timepicker.dropdown-menu {
  padding: 0 4px;
}
.timepicker.dropdown-menu button {
  outline: none;
  border: 0px;
}
.timepicker.dropdown-menu tbody button {
  padding: 6px;
}
.medialabels .medialabels-name-clickable {
  cursor: pointer;
  margin-right: 3px;
  margin-left: 1px;
}
.medialabels .medialabels--form-new > input {
  width: 100%;
}
.medialabels .medialabels--label {
  height: 28px;
  margin: 3px;
  padding: 2px;
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  display: inline-table;
}
.medialabels .medialabels--label span input {
  height: 20px;
}
.medialabels .removeBtn,
.medialabels .saveBtn,
.medialabels .editBtn {
  margin-left: 3px;
  opacity: 0.7;
  cursor: pointer;
}
.medialabels .removeBtn:hover,
.medialabels .saveBtn:hover,
.medialabels .editBtn:hover {
  opacity: 1;
}
.medialabels .medialabels--colorPicker-input {
  width: 50px;
  display: inline;
}
.medialabels--inline {
  max-width: 290px;
}
.schedule-content .schedule-content-item {
  padding: 5px;
}
@font-face {
  font-family: '4000bold';
  src: url('assets/4000-bold.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: '4000heavyleft';
  src: url('assets/4000-heavy-left.otf');
}
@font-face {
  font-family: '4000heavyright';
  src: url('assets/4000-heavy-right.otf');
}
@font-face {
  font-family: '4000heavy';
  src: url('assets/4000-heavy.otf');
}
@font-face {
  font-family: '4000italic';
  src: url('assets/4000-italic.otf');
}
@font-face {
  font-family: '4000regular';
  src: url('assets/4000-regular.otf');
}
@font-face {
  font-family: 'ralewayregular';
  src: url('assets/raleway-regular-webfont.woff2') format('woff2'), url('assets/raleway-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.landscape .scrolling-image-container {
  overflow: hidden;
  top: 0px;
  height: 100%;
  width: 177.77vh;
  position: absolute;
  z-index: 1;
}
.landscape .scrolling-image:before {
  background: url("assets/scroller_l.png") repeat-y;
  position: absolute;
  background-size: 100%;
  content: "";
  height: 6056px;
  width: 5.3%;
  top: 0px;
  left: 0px;
  z-index: 3;
  animation: slide_l 130s linear infinite;
}
.landscape .scrolling-image:after {
  z-index: 3;
}
@keyframes slide_l {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translateY(-2271px);
  }
}
.landscape .scrolling-image-alt {
  position: relative;
  top: 100%;
  left: 0%;
  z-index: 3;
  transform: rotate(180deg);
}
.portrait .scrolling-image-container {
  overflow: hidden;
  top: 0px;
  height: 100%;
  width: 75vh;
  position: absolute;
  z-index: 1;
}
.portrait .scrolling-image {
  background: url("assets/scroller_p.png") repeat-x;
  background-size: auto 100%;
  top: 0px;
  left: 0px;
  height: 5.5%;
  width: 5076px;
  z-index: 3;
  animation: slide_p 130s linear infinite;
}
@keyframes slide_p {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-1692px);
  }
}
.portrait .scrolling-image-alt {
  position: relative;
  top: 89%;
  left: 0%;
  z-index: 3;
}
player,
player-preview {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #000000;
  color: #FFF;
}
player #stageWrapper.landscape,
player-preview #stageWrapper.landscape {
  padding: 0 0vh 0 0vh;
  width: 177.77vh;
  min-height: 100vh;
  margin: 0 auto;
}
player #stageWrapper.landscape #stage,
player-preview #stageWrapper.landscape #stage {
  position: relative;
  background-color: #000000;
  width: 177.77vh;
  height: 100vh;
}
player #stageWrapper.portrait,
player-preview #stageWrapper.portrait {
  padding: 0 0vh 0 0vh;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}
player #stageWrapper.portrait #stage,
player-preview #stageWrapper.portrait #stage {
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
player {
  cursor: none;
}
.previewNotification {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5em;
  text-align: center;
}
file-media-image-player,
file-media-video-player {
  display: block;
  text-align: center;
}
service-announcement-player {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  text-transform: uppercase;
  color: white;
}
service-announcement-player .drawable {
  position: absolute;
}
service-announcement-player .headline {
  font-family: '4000heavy';
  font-size: 9.8vh;
}
service-announcement-player .body {
  font-family: '4000heavy';
  white-space: pre-line;
}
service-announcement-player.landscape {
  font-size: 5.6vh;
}
service-announcement-player.landscape .drawable {
  top: 43.3%;
  right: 5.7%;
  bottom: 0;
  left: 5.7%;
}
service-announcement-player.landscape .body {
  margin-top: -2%;
  line-height: 94.2%;
  height: 37vh;
  overflow: hidden;
}
service-announcement-player.portrait {
  width: 77.79vh;
  font-size: 3.8vh;
}
service-announcement-player.portrait .drawable {
  top: 44.5%;
  bottom: 0;
  width: 77.79vh;
}
service-announcement-player.portrait .headline {
  font-family: '4000heavy';
  font-size: 6.5vh;
}
service-announcement-player.portrait .body {
  margin-top: -3.3%;
  line-height: 119%;
  height: 41vh;
  overflow: hidden;
}
social-media-player {
  display: block;
  width: 100%;
  height: 100%;
  font-family: '4000heavy';
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  color: white;
}
social-media-player .drawable {
  position: absolute;
  height: 100vh;
  width: inherit;
}
social-media-player .frame {
  display: inline-flex;
  vertical-align: middle;
}
social-media-player .frame img {
  margin: auto;
}
social-media-player .profile .is-hashtag {
  font-family: '4000heavy';
}
social-media-player .profile .username {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  float: left;
}
social-media-player .profile .username--container {
  display: block;
  position: relative;
}
social-media-player .profile .post--container {
  display: block;
  position: relative;
  word-break: break-word;
  text-align: center;
}
social-media-player .profile img {
  display: inline;
  float: left;
}
social-media-player.landscape .profile {
  width: 48.5vh;
  margin-top: 14vh;
  margin-left: 9.4vh;
  float: left;
}
social-media-player.landscape .profile .username--container {
  position: absolute;
  top: 21.1vh;
  left: 9.4vh;
}
social-media-player.landscape .profile .username {
  max-width: 83vh;
  margin-bottom: 0;
  font-size: 4.8vh;
  margin-left: -31.4vh;
  margin-top: 3.6vh;
  text-transform: uppercase;
}
social-media-player.landscape .profile .post--container {
  width: 58.4vh;
  margin-top: 27.9vh;
  font-size: 4.5vh;
  line-height: 1.22;
  height: 56vh;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  text-align: left;
  text-transform: uppercase;
  left: -1.7vh;
}
social-media-player.landscape .profile img {
  height: 10vh;
  margin-top: -9vh;
  margin-left: 13vh;
}
social-media-player.landscape .frame {
  margin-top: 9.9vh;
  margin-left: 24vh;
  width: 80vh;
  height: 80vh;
}
social-media-player.landscape .frame img {
  max-width: 100vh;
  max-height: 100vh;
}
social-media-player.landscape .frame video {
  margin: 0 auto;
}
social-media-player.portrait {
  width: 77.79vh;
}
social-media-player.portrait .profile .username--container {
  position: absolute;
  bottom: 21.1vh;
  left: 36.9vh;
}
social-media-player.portrait .profile .username {
  font-size: 3.2vh;
  text-transform: uppercase;
}
social-media-player.portrait .profile .post--container {
  position: absolute;
  top: 81.8vh;
  left: 8.9vh;
  right: 8.9vh;
  height: 16.1vh;
  font-size: 3.3vh;
  line-height: 114%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: uppercase;
  text-align: left;
}
social-media-player.portrait .profile img {
  height: 6.6vh;
  margin-top: -0.3vh;
  margin-left: -44.3vh;
  float: none;
}
social-media-player.portrait .frame {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8.5vh;
  margin-left: 9.5vh;
  width: 59vh;
  height: 59vh;
  overflow: hidden;
}
social-media-player.portrait .frame img {
  width: 71vh;
  height: 71vh;
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: #e0e0e0 !important;
  /* Light grey background */
  color: #333 !important;
  /* Dark grey text */
}
.h4 {
  font-weight: bold;
}
.panel {
  border-radius: 4px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  background-color: #ffffff !important;
  /* White panel backgrounds */
  border: none !important;
  padding: 5px !important;
}
.btn-default {
  background-color: white !important;
  /* Vibrant orange for buttons */
  color: #333 !important;
  border-color: #ccc !important;
  /* Border color to match the background */
  border-radius: 4px !important;
}
.btn-default:hover {
  background-color: #ebebeb !important;
  color: #333 !important;
}
.btn-primary {
  background-color: #f57c00 !important;
  /* Vibrant orange for buttons */
  color: white !important;
  border-color: #f57c00 !important;
  /* Border color to match the background */
  border-radius: 4px !important;
}
.panel {
  border-color: transparent;
  /* Remove default panel border */
  box-shadow: none;
  /* Remove default box shadow */
}
/* Style the panel heading */
.panel-heading {
  background-color: transparent !important;
  /* Remove background color */
  border-bottom: 3px solid #f8ca44 !important;
  /* Orange bottom border for separation */
  color: #333;
  /* Set text color */
  margin-bottom: 10px !important;
}
/* Ensure the panel body stands out against the background */
.panel-body {
  background-color: #ffffff;
  /* White background for the body */
}
/* General navbar styling */
.navbar-default {
  background-color: #ffffff;
  /* White background for a cleaner look */
  border-color: #f57c00;
  /* Orange border for a subtle hint of the primary color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
}
/* Navbar brand and link styling */
.navbar-default .navbar-brand,
.navbar-default .navbar-nav > li > a {
  color: #333;
  /* Dark grey for text, ensuring readability */
}
/* Navbar link hover and focus styles */
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #f57c00;
  /* Orange text on hover for a pop of color */
  background-color: transparent;
  /* Maintain a transparent background on hover */
}
/* Active link styling */
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #ffffff;
  /* White text for active link */
  background-color: #f57c00;
  /* Orange background for the active item */
}
/* Responsive toggle button color */
.navbar-default .navbar-toggle {
  border-color: #f57c00;
  /* Orange border for the toggle */
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #333;
  /* Dark grey icon to stand out against the white navbar */
}
/* Dropdown menu styling */
.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #333;
  /* Dark grey for dropdown links for readability */
}
.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
  background-color: #f57c00;
  /* Orange background for dropdown items on hover/focus */
  color: white;
  /* White text for contrast */
}
.panel {
  box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3);
}
.panel-default > .panel-heading {
  color: #333333;
  background-color: #dbdbdb;
  border-color: #b3b1b1;
}
.notify-center {
  color: #777777;
  text-align: center;
}
.list-striped {
  margin-bottom: 0;
}
.list-striped > * {
  border-bottom: 1px solid #ddd;
}
.list-striped > *:hover {
  background-color: #f5f5f5;
}
.list-striped > *:nth-child(even) {
  background-color: #f9f9f9;
}
.list-striped > *:last-child {
  border: 0;
}
.scene-content > li {
  padding: 10px;
  min-height: 50px;
}
.scene-content .interval {
  border: dashed 1px #e7e7e7;
  padding: 10px;
}
.scene-content-duration {
  display: inline;
  width: 120px;
}
.scene-content-duration-btns {
  display: inline;
}
.ib {
  display: inline-block;
}
.form-sub {
  margin-top: 10px;
}
.ellipsis-container {
  display: inline-block;
  max-width: 335px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-media-library {
  display: flex;
  flex-wrap: wrap;
}
.flex-media-item {
  display: flex;
  flex-direction: column;
  min-width: 345px;
  max-width: 345px;
  padding: 5px;
  margin: 2px;
  border-radius: 4px;
  background-color: #f9f9f9;
  border: 1px solid #f57c00;
}
.flex-media-item:hover {
  background-color: #f5f5f5;
}
.flex-media-item .content {
  display: flex;
}
.flex-media-item .content .thumb {
  height: 100px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-right: 5px;
}
.flex-media-item .content .controls {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.flex-media-item .content .controls > * {
  flex: 1;
  margin-bottom: 2px;
}
.flex-media-item .content .controls > *:last-child {
  margin-bottom: 0;
}
.flex-media-item.create {
  min-height: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
asset-upload-drop-zone.flex-media-item {
  min-height: 100px;
  border: 2px dashed #e7e7e7;
  transition: all 500ms ease;
}
asset-upload-drop-zone.flex-media-item .asset-upload-drag-over {
  color: #3c763d;
}
asset-upload-drop-zone.flex-media-item .asset-upload-title {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
asset-upload-drop-zone.flex-media-item .asset-upload-title small,
asset-upload-drop-zone.flex-media-item .asset-upload-title .small {
  font-weight: 400;
  line-height: 1;
  color: #777777;
}
asset-upload-drop-zone.flex-media-item .asset-upload-title small,
asset-upload-drop-zone.flex-media-item .asset-upload-title .small {
  font-size: 75%;
}
asset-upload-drop-zone.flex-media-item .asset-upload-files-remaining,
asset-upload-drop-zone.flex-media-item .asset-upload-bytes-remaining {
  text-align: center;
}
.btn-discreet-danger {
  color: #333;
  background-color: #FFF;
  border-color: #CCC;
}
.btn-discreet-danger:hover,
.btn-discreet-danger:focus,
.btn-discreet-danger.focus,
.btn-discreet-danger:active,
.btn-discreet-danger.active,
.open > .dropdown-toggle.btn-discreet-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.btn-discreet-danger:active,
.btn-discreet-danger.active,
.open > .dropdown-toggle.btn-discreet-danger {
  background-image: none;
}
.btn-discreet-danger.disabled,
.btn-discreet-danger[disabled],
fieldset[disabled] .btn-discreet-danger,
.btn-discreet-danger.disabled:hover,
.btn-discreet-danger[disabled]:hover,
fieldset[disabled] .btn-discreet-danger:hover,
.btn-discreet-danger.disabled:focus,
.btn-discreet-danger[disabled]:focus,
fieldset[disabled] .btn-discreet-danger:focus,
.btn-discreet-danger.disabled.focus,
.btn-discreet-danger[disabled].focus,
fieldset[disabled] .btn-discreet-danger.focus,
.btn-discreet-danger.disabled:active,
.btn-discreet-danger[disabled]:active,
fieldset[disabled] .btn-discreet-danger:active,
.btn-discreet-danger.disabled.active,
.btn-discreet-danger[disabled].active,
fieldset[disabled] .btn-discreet-danger.active {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-discreet-danger[disabled] {
  color: #333;
  background-color: #FFF;
  border-color: #CCC;
}
.btn-discreet-danger .badge {
  color: #FFF;
  background-color: #333;
}
.btn-discreet-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-interval-now {
  color: #FFF;
  background-color: #00b500;
  border-color: #009c00;
}
.btn-interval-now:focus,
.btn-interval-now.focus {
  color: #FFF;
  background-color: #008200;
  border-color: #001c00;
}
.btn-interval-now:hover {
  color: #FFF;
  background-color: #008200;
  border-color: #005e00;
}
.btn-interval-now:active,
.btn-interval-now.active,
.open > .dropdown-toggle.btn-interval-now {
  color: #FFF;
  background-color: #008200;
  background-image: none;
  border-color: #005e00;
}
.btn-interval-now:active:hover,
.btn-interval-now.active:hover,
.open > .dropdown-toggle.btn-interval-now:hover,
.btn-interval-now:active:focus,
.btn-interval-now.active:focus,
.open > .dropdown-toggle.btn-interval-now:focus,
.btn-interval-now:active.focus,
.btn-interval-now.active.focus,
.open > .dropdown-toggle.btn-interval-now.focus {
  color: #FFF;
  background-color: #005e00;
  border-color: #001c00;
}
.btn-interval-now.disabled:hover,
.btn-interval-now[disabled]:hover,
fieldset[disabled] .btn-interval-now:hover,
.btn-interval-now.disabled:focus,
.btn-interval-now[disabled]:focus,
fieldset[disabled] .btn-interval-now:focus,
.btn-interval-now.disabled.focus,
.btn-interval-now[disabled].focus,
fieldset[disabled] .btn-interval-now.focus {
  background-color: #00b500;
  border-color: #009c00;
}
.btn-interval-now .badge {
  color: #00b500;
  background-color: #FFF;
}
.btn-interval-future {
  color: #FFF;
  background-color: #97db97;
  border-color: #84d484;
}
.btn-interval-future:focus,
.btn-interval-future.focus {
  color: #FFF;
  background-color: #71ce71;
  border-color: #38a138;
}
.btn-interval-future:hover {
  color: #FFF;
  background-color: #71ce71;
  border-color: #57c557;
}
.btn-interval-future:active,
.btn-interval-future.active,
.open > .dropdown-toggle.btn-interval-future {
  color: #FFF;
  background-color: #71ce71;
  background-image: none;
  border-color: #57c557;
}
.btn-interval-future:active:hover,
.btn-interval-future.active:hover,
.open > .dropdown-toggle.btn-interval-future:hover,
.btn-interval-future:active:focus,
.btn-interval-future.active:focus,
.open > .dropdown-toggle.btn-interval-future:focus,
.btn-interval-future:active.focus,
.btn-interval-future.active.focus,
.open > .dropdown-toggle.btn-interval-future.focus {
  color: #FFF;
  background-color: #57c557;
  border-color: #38a138;
}
.btn-interval-future.disabled:hover,
.btn-interval-future[disabled]:hover,
fieldset[disabled] .btn-interval-future:hover,
.btn-interval-future.disabled:focus,
.btn-interval-future[disabled]:focus,
fieldset[disabled] .btn-interval-future:focus,
.btn-interval-future.disabled.focus,
.btn-interval-future[disabled].focus,
fieldset[disabled] .btn-interval-future.focus {
  background-color: #97db97;
  border-color: #84d484;
}
.btn-interval-future .badge {
  color: #97db97;
  background-color: #FFF;
}
.btn-interval-past {
  color: #FFF;
  background-color: #f99090;
  border-color: #f87878;
}
.btn-interval-past:focus,
.btn-interval-past.focus {
  color: #FFF;
  background-color: #f66060;
  border-color: #e40c0c;
}
.btn-interval-past:hover {
  color: #FFF;
  background-color: #f66060;
  border-color: #f53e3e;
}
.btn-interval-past:active,
.btn-interval-past.active,
.open > .dropdown-toggle.btn-interval-past {
  color: #FFF;
  background-color: #f66060;
  background-image: none;
  border-color: #f53e3e;
}
.btn-interval-past:active:hover,
.btn-interval-past.active:hover,
.open > .dropdown-toggle.btn-interval-past:hover,
.btn-interval-past:active:focus,
.btn-interval-past.active:focus,
.open > .dropdown-toggle.btn-interval-past:focus,
.btn-interval-past:active.focus,
.btn-interval-past.active.focus,
.open > .dropdown-toggle.btn-interval-past.focus {
  color: #FFF;
  background-color: #f53e3e;
  border-color: #e40c0c;
}
.btn-interval-past.disabled:hover,
.btn-interval-past[disabled]:hover,
fieldset[disabled] .btn-interval-past:hover,
.btn-interval-past.disabled:focus,
.btn-interval-past[disabled]:focus,
fieldset[disabled] .btn-interval-past:focus,
.btn-interval-past.disabled.focus,
.btn-interval-past[disabled].focus,
fieldset[disabled] .btn-interval-past.focus {
  background-color: #f99090;
  border-color: #f87878;
}
.btn-interval-past .badge {
  color: #f99090;
  background-color: #FFF;
}
back-button {
  margin-right: 5px;
}
textarea {
  resize: vertical;
}
.modal-video-container {
  max-width: 100%;
  max-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-video-container video {
  max-width: 100%;
  max-height: 100%;
}
.scene-content-item.placeholder {
  /* styles for the placeholder */
  background-color: #b9d4ab;
  border: 2px dashed #888;
  min-height: 20px;
}
.video4k-label {
  position: absolute;
  padding: 2px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  font-size: 9px;
  z-index: 10;
}
.player-log-table tr.highlight td {
  background-color: #f57c00;
  color: #fff;
}
.player-log-table tr.removed td {
  text-decoration: line-through;
}
.truncate {
  width: 250px;
  /* Define the width of the container */
  white-space: nowrap;
  /* Prevent text from wrapping to a new line */
  overflow: hidden;
  /* Hide text that exceeds the container */
  text-overflow: ellipsis;
  /* Add an ellipsis at the cut-off point */
}

.medialabels {
	.medialabels-name-clickable {
		cursor: pointer;
		margin-right: 3px;
		margin-left: 1px;
	}

	.medialabels--form-new > input {
		width: 100%;
	}

	.medialabels--label {
		height: 28px;
		margin: 3px;
		padding: 2px;
		border-radius: 3px;
		border-style: solid;
		border-width: 2px;
		display: inline-table;

		span {
			input {
				height: 20px;
			}
		}
	}

	.removeBtn, .saveBtn, .editBtn {
		margin-left: 3px;
		opacity: 0.7;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}

	.medialabels--colorPicker-input {
		width: 50px;
		display: inline;
	}
}

.medialabels--inline {
	max-width: 290px;
}
